import React from 'react';
import cx from 'classnames';
import { Theme, mapThemeToClassNames } from 'ui/themes';

export type PageSectionProps = {
    children?: React.ReactNode;
    splashScreen?: boolean;
    inner?: boolean;
    nav?: boolean;
    theme?: Theme;
    extraClass?: string;
    jsClass?: string;
    isFullWidth?: boolean;
    hasPadding?: boolean;
    hasOnlyTopPadding?: boolean;
    isPrimary?: boolean;
    hasFullWidthOnMobile?: boolean;
};

function PageSection(props: PageSectionProps) {
    const {
        children,
        splashScreen,
        inner,
        nav,
        theme = Theme.DATE,
        isFullWidth,
        hasPadding,
        hasOnlyTopPadding,
        extraClass,
        jsClass,
        isPrimary,
        hasFullWidthOnMobile,
    } = props;

    const className = cx({
        page__section: true,
        'page__section--splash-screen': splashScreen,
        'page__section--categories-menu': nav,
        'page__section--full-width': isFullWidth,
        'page__section--padded': hasPadding,
        'page__section--padded-only-top': hasOnlyTopPadding,
        'page__section--primary': isPrimary,
        'page__section-mobile-full-width': hasFullWidthOnMobile,
        [mapThemeToClassNames[theme]]: true,
        [extraClass as string]: extraClass,
        [jsClass as string]: jsClass,
    });

    const PageSectionTag = nav ? 'nav' : 'section';

    const content =
        !splashScreen && !inner ? children : <div className="page__section-inner">{children}</div>;

    return <PageSectionTag className={className}>{content}</PageSectionTag>;
}

PageSection.Theme = Theme;

export default PageSection;
